.footer{
  background-color: $colour-brown;
  padding: 95px 0px;
  background-image: url('/assets/images/texture.jpg');
  background-size: auto;
  background-repeat: repeat;
  background-position: center;
  a{
    color: $colour-body;
  }

  &-container{
    padding: 0px 15px;
  }
  &-cols{
    display: flex;
    .footer-col{
      flex: 25%;
      margin: 0px 15px;
    }
  }
  &-bottom{
    margin-top: 75px;
    text-align: center;
  }
  &-logo{
    width: 250px;
    max-width: 100%;
  }
  &-socials{
    .social-item{
      display: inline-block;
      vertical-align: middle;
      margin: 12px 15px;
      img{
        width: 26px;
      }
    }
  }

  p{
    margin-bottom: 10px;
    line-height: 24px;
  }

}

@media(max-width: 1199px){
  .footer-cols{
    flex-wrap: wrap;
    .footer-col{
      flex: 50%;
      max-width: 50%;
      margin: 0px;
      padding: 15px;
      box-sizing: border-box;
    }
  }
}

@media(max-width: 992px){
  .footer-cols{
    .footer-col:first-child{
      padding-top: 0px;
    }
    .footer-col {
      flex: 100%;
      max-width: 100%;
    }
  }
}
