.header-container{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 300;
  background-color: $colour-white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.32);
}

.header{
  display: flex;
  width: 100%;
  padding: 8px 7% 5px;
  box-sizing: border-box;
  ul{
    padding-left: 0px;
  }
  a{
    color: $colour-body;
  }
  .header-logo{
    width: 180px;
    align-self: center;
    a:hover{
      opacity: 1;
      .header-logo-image{
        transform: scale(1.05);
      }
    }
    .header-logo-image{
      max-width: 100%;
      transition: 0.45s ease;
    }
  }

  .header-nav{
    flex: 1;
    align-self: center;
  }

  .main-menu{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    .menu-item.active > a{
     color: $colour-brown;
     font-weight: 600;
    }
    .menu-item > a{
      text-decoration: none;
    }
    .menu-item{
      padding: 8px 18px;
    }
  }

  .header-book{
    align-self: center;
    margin-left: 8px;
    box-sizing: border-box;
  }
  .menu-toggle{
    display: none;
    margin-left: auto;
    width: 45px;
    margin-right: 15px;

    .bar1, .bar2, .bar3 {
      background-color: $colour-brown;
      width: 45px;
      height: 2px;
      margin: 10px 0;
      transition: 0.4s;
    }
  }

  .header-nav.open .menu-toggle{
    .bar1 {
      -webkit-transform: rotate(-45deg) translate(-10px, 10px);
      transform: rotate(-45deg) translate(-10px, 10px);
    }
    .bar2 {opacity: 0;}
    .bar3 {
      -webkit-transform: rotate(45deg) translate(-6px, -8px);
      transform: rotate(45deg) translate(-6px, -8px);
    }
  }
}

@media(min-width: 2000px){
  .header {
    padding: 8px 0 5px;
    max-width: 1480px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media(min-width: 1300px){
  .header .main-menu {
    display: flex !important;
  }
}
@media (max-width: 1500px){
  .header {
    max-width: 1500px;
    margin: auto;
    width: 95%;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (max-width: 1299px){
  .header{
    .header-nav{
      position: relative;
    }
    .main-menu{
      display: none;
      position: absolute;
      background-color: $colour-white;
      width: 275px;
      padding: 35px 15px 15px;
      top: 41px;
      right: 0px;
      box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.32);
    }
    .menu-toggle{
      display: block;
    }
  }
}

@media(max-width: 675px){
  .header{
    padding: 21px 0px 0px;
    width: 100%;
    display: block;
    .header-logo{
      width: 135px;
      padding-left: 35px;
      position: relative;
      z-index: 10;
    }
    .header-nav{
      top: -50px;
      right: 0px;
      margin-bottom: -46px;
    }
    .main-menu {
      box-shadow: unset;
      position: unset;
    }
    .header-book {
      width: 100%;
      flex: 100%;
      max-width: unset;
      margin-left: 0px;
      margin-top: 15px;
    }
    .menu-toggle{
      margin-right: 35px;
    }
  }
}
