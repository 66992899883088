@font-face{
  font-family: 'Typical Writer';
  src: url('/assets/fonts/typical-writer.woff2') format('woff2'),
       url('/assets/fonts/typical-writer.woff') format('woff'),
       url('/assets/fonts/typical-writer.ttf')  format('truetype')
}

body{
  color: $colour-body;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0px;
}

h1, h2, h3, .btn, .font-secondary{
    font-family: 'Typical Writer', Courier, monospace;
    font-weight: 400;
}

h4, h5, h6{
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6, p{
  margin-top: 0px;
}

h1{
  font-size: 36px;
  line-height: 43px;
}

h2{
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 12px;
}

h3{
  font-size: 21px;
  line-height: 23px;
}

h4{
  font-size: 18px;
  line-height: 21px;
}

h5{
  font-size: 16px;
  line-height: 18px;
}

h6{
  font-size: 14px;
  line-height: 16px;

}

h3,h4,h5,h6{
    margin-bottom: 8px;
}

.btn{
  font-size: 18px;
  line-height: 21px;
}

a:not(.btn){
  transition: 0.25s ease;
}

a, .btn{
  &:hover{
    cursor: pointer;
  }
}
a{
  color: $colour-brown;
  &:hover{
    opacity: 1;
    color: darken($colour-brown, 30%);
  }
}

.rich-text{
  margin-top: 75px;
  margin-bottom: 75px;
  li{
    line-height: 25px;
    margin-bottom: 12px;
  }
}

@media (hover: hover) {
  a:not(.btn):hover{
    opacity: 0.65;
  }
}
