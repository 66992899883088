.testimonials-block{
  text-align: center;
  margin-top: 75px;
  margin-bottom: 75px;
  .testimonials-details{
    display: flex;
    justify-content: center;
    margin: 25px 0px;
  }
  .testimonials-image{
    height: 100px;
    width: 100px;
    border-radius: 75px;
    margin-right: 30px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  }

  .testimonials-slide{
    padding: 0px 75px;
    box-sizing: border-box;
  }
  .testimonials-name{
    align-self: center;
    margin-bottom: 0px;
  }
}

@media(max-width: 1199px){
  .testimonials-block .testimonials-slide{
    padding: 0px 55px;
  }
}
@media(max-width: 767px){
  .testimonials-block{
    text-align: left;
    .testimonials-slide{
      padding: 0px;
    }
    .testimonials-image{
      display: none;
    }
    .testimonials-carousel{
      margin-top: 75px;
    }
    .slick-arrow {
      top: -32px;
    }
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
    .testimonials-details{
      display: block;
    }
  }
}
