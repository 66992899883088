.accordion-element{
  margin-top: 75px;
  margin-bottom: 75px;
}
.accordion-row{
  margin-bottom: 32px;
  .btn-slide-item{
     display: none;
  }

  .btn-accordion {
    padding: 12px 28px 12px 0px;
    margin-bottom: 18px;
    border-bottom: 1px solid $colour-black;
    position: relative;
    transition: 0.35s ease;

    &.open{
      color: $colour-brown;

      .arrow{
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }

    }

    .arrow{
      display: block;
      position: absolute;
      height: 30px;
      width: 30px;
      right: 0px;
      bottom: 6px;
      transition: 0.35s ease;
      background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJGb3JtLS8tRHJvcGRvd24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMjEuMDAwMDAwLCAtNDEuMDAwMDAwKSIgc3Ryb2tlPSIjRDFCMTlCIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlBhdGgiIHBvaW50cz0iMjIyIDQyIDIyOSA0OSAyMzYgNDIiPjwvcG9seWxpbmU+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') no-repeat 6px/20px;

    }
  }

}

@media(hover: hover){
  .accordion-row .btn-accordion:hover{
    cursor: pointer;
    color: $colour-brown;
  }
}
