//booking box scss from header-carousel.scss file
.video-container{
  overflow: hidden;
  width: 100%;
  height: 100vh;
  height: calc(100vh - 88px);
  min-height: 750px;
  position: relative;
}
.video-header-container{
  margin-bottom: -1px;
}

.video-header{
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-header-link{
  position: absolute;
  bottom: 8px;
  color: white;
  right: 85px;
  padding-left: 30px;
  &:hover{
    cursor: pointer;
  }
  &::before{
    background-image: url('/assets/images/play-button.png');
    background-size: contain;
    width: 21px;
    height: 21px;
    content:'';
    position: absolute;
    top: 35%;
    left: 0;
    transform: translateY(-50%);
  }
}


@media(max-width: 1199px){
  .video-container {
    height: 75vh;
    min-height: unset;
  }
}

@media(max-width: 767px){
  .video-container {
    min-height: 750px;
  }
}

@media(max-width: 600px){
  .video-header-link{
    width: 208px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
}
