.hero-image{
  width: 100%;
  height: 750px;
  margin-bottom: 132px;
  margin-top: -113px;
  .hero-image-limit{
    position: relative;
    height: 100%;
  }
  .hero-image-title{
    position: absolute;
    bottom: -45px;
    background-color: $colour-brown;
    padding: 28px 35px;
    background-image: url('/assets/images/texture.jpg');
    background-size: auto;
    background-repeat: repeat;
    background-position: center;
    margin-right: 35px;
    h1{
      margin-bottom: 0px;
    }
  }
  .hero-image-tape {
    top: -21px;
    left: 50%;
    width: 175px;
    height: 40px;
    transform: translate(-50%);
    position: absolute;
    z-index: 20;
  }
}

@media(max-width: 1920px){
  .hero-image {
    height: 650px;
  }
}

@media(max-width: 1199px){
  .hero-image {
    height: 550px;
  }
}

@media(max-width: 992px){
  .hero-image{
    height: 450px;
    .hero-image-title{
      margin-right: 55px;
    }
  }
}

@media(max-width: 550px){
  .hero-image{
    height: 350px;
    .hero-image-title {
        margin-right: 25px;
        padding: 28px 15px 15px;
    }
  }
}
