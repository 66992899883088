.image-gallery {
  position: relative;
  margin-top: 105px;
  margin-bottom: 75px;
  .tape-first, .tape-second{
    position: absolute;
    transform: translateX(-50%);
  }

  .image-gallery-halves{
    display: flex;
  }
  .image-gallery-first, .image-gallery-second{
    width: 50%;
  }
  .image-gallery-first{
    padding-right: 15px;
  }

  .image-gallery-second{
    padding-left: 15px;
  }

  .image-gallery-images{
    display: flex;
    flex-wrap: wrap;

    .image-gallery-image{
      box-sizing: border-box;
      .base-background{
        width: 100%;
        height: 100%;
        box-shadow: 0px 6px 24px rgba(0,0,0, 0.5);
      }

    }
  }

}
//six images version
.image-gallery.six-images{
  .tape-first{
    top: 180px;
    left: 25%;
  }
  .tape-second{
    top: -35px;
    left: 76%;
  }
  .image-gallery-images{

    .image-gallery-image{
      width: 50%;
      height: 195px;
    }

    &.image-gallery-first{
      .image-gallery-image:first-child{
        padding-right: 15px;
      }
      .image-gallery-image:nth-child(2){
        padding-left: 15px;
      }
      .image-gallery-image:last-child{
        padding-top: 30px;
        width: 100%;
        height: 405px;
      }
    }

    &.image-gallery-second{
      .image-gallery-image:first-child{
        padding-bottom: 30px;
        width: 100%;
        height: 405px;

      }
      .image-gallery-image:nth-child(2){
        padding-right: 15px;
      }
      .image-gallery-image:last-child{
        padding-left: 15px;
      }
    }

  }
}
//three images version
.image-gallery.three-images{
  .tape-first{
    top: -35px;
    left: 33%;
  }
  .tape-second{
    top: -35px;
    left: 84%;
  }
  .image-gallery-images{
    flex-direction: column;
    height: 490px;

    .image-gallery-image{
      width: 33.3333%;
      height: 245px;
    }


    .image-gallery-image:first-child{
      padding-right: 15px;
      height: 490px;
      width: 66.6666%;
    }
    .image-gallery-image:nth-child(2){
      padding-bottom: 15px;
      padding-left: 15px;
    }
    .image-gallery-image:last-child{
      padding-top: 15px;
      padding-left: 15px;
    }

  }
}

//two images version
.image-gallery.two-images{
  .tape-first{
    top: -35px;
    left: 25%;
  }
  .tape-second{
    top: -35px;
    left: 75%;
  }
  .image-gallery-images{
    .image-gallery-image{
      width: 50%;
      height: 490px;
    }
    .image-gallery-image:first-child{
      padding-right: 15px;
    }
    .image-gallery-image:last-child{
      padding-left: 15px;
    }

  }
}

//one images version
.image-gallery.one-image{
  .tape-first{
    top: -35px;
    left: 50%;
  }
  .tape-second{
    display: none;
  }
  .image-gallery-images{
    .image-gallery-image{
      width: 100%;
      height: 600px;
    }
  }
}

@media(max-width: 1299px){
  .image-gallery.six-images{
    .tape-first{
      top: 135px;
    }
    .image-gallery-images{
      .image-gallery-image{
        height: 150px;
      }

      &.image-gallery-first{
        .image-gallery-image:last-child{
          height: 360px;
        }
      }

      &.image-gallery-second{
        .image-gallery-image:first-child{
          height: 360px;
        }
      }
    }
  }
  .image-gallery.one-image{
    .image-gallery-images .image-gallery-image{
      height: 510px;
    }
  }
  .image-gallery.two-images{
    .image-gallery-images .image-gallery-image{
      width: 50%;
      height: 250px;
    }
  }

}

@media(max-width: 992px){
  .image-gallery {
    .image-gallery-halves{
      display: block;
    }
    .image-gallery-first, .image-gallery-second{
      width: 100%;
      padding: 0px;
    }
  }

  .image-gallery.six-images{
    .tape-first{
      top: 625px;
      left: 50%;
    }
    .tape-second{
      left: 50%;
    }
    .image-gallery-images{
      .image-gallery-image{
        height: 195px;
      }

      &.image-gallery-first{
        .image-gallery-image:last-child{
          height: 440px;
          order: 1;
          padding-top: 0px;
          padding-bottom: 30px;
        }
        .image-gallery-image:first-child{
           order: 2;
        }
        .image-gallery-image:nth-child(2){
          order: 3;
        }
      }

      &.image-gallery-second{
        .image-gallery-image:first-child{
          height: 440px;
          padding-top: 30px;
        }
      }
    }
  }

  .image-gallery.three-images{
    .tape-second {
      top: -30px;
      width: 180px;
    }
    .image-gallery-images{
      height: 350px;
      .image-gallery-image{
        height: 175px;
      }
      .image-gallery-image:first-child{
        height: 350px;
      }
    }
  }
  .image-gallery.one-image{
    .image-gallery-images .image-gallery-image{
      height: 400px;
    }
  }

}

@media(max-width: 767px){
  .image-gallery.six-images{
    .tape-first{
      top: 485px;
    }
    .image-gallery-images{
      .image-gallery-image{
        height: 150px;
      }

      &.image-gallery-first{
        .image-gallery-image:last-child{
          height: 350px;
        }
      }

      &.image-gallery-second{
        .image-gallery-image:first-child{
          height: 350px;
        }
      }
    }
  }
  .image-gallery.three-images{
    .tape-first {
      left: 50%;
    }
    .tape-second {
      display: none;
    }
    .image-gallery-images{
      height: auto;
      flex-direction: row;
      .image-gallery-image{
        height: 175px;
        width: 50%;
      }
      .image-gallery-image:first-child{
        width: 100%;
        padding-right: 0px;
        padding-bottom: 30px;
        height: 350px;
      }
      .image-gallery-image:nth-child(2){
        padding: 0px 15px 0px 0px;
      }
      .image-gallery-image:last-child{
        padding: 0px 0px 0px 15px;
      }
    }
  }

  .image-gallery.one-image{
    .image-gallery-images .image-gallery-image{
      height: 300px;
    }
  }
  .image-gallery.two-images{
    .tape-first{
      top: -35px;
      left: 50%;
    }
    .tape-second{
      top: 50%;
      left: 50%;
    }
    .image-gallery-images{
      .image-gallery-image{
        width: 100%;
        height: 300px;
      }
      .image-gallery-image:first-child{
        padding-right: 0px;
      }
      .image-gallery-image:last-child{
        padding-left: 0px;
        padding-top: 30px;
      }
    }
  }



}

@media(max-width: 550px){
  .image-gallery.six-images{
    .tape-first{
      top: 375px;
    }
    .image-gallery-images{
      .image-gallery-image{
        height: 120px;
      }

      &.image-gallery-first{
        .image-gallery-image:last-child{
          height: 272px;
        }
      }

      &.image-gallery-second{
        .image-gallery-image:first-child{
          height: 272px;
        }
      }
    }
  }
  .image-gallery.three-images{
    .image-gallery-images{
      .image-gallery-image{
        height: 120px;
      }
      .image-gallery-image:first-child{
        height: 272px;
      }
    }
  }
  .image-gallery.one-image{
    .image-gallery-images .image-gallery-image{
      height: 272px;
    }
  }
  .image-gallery.two-images{
    .image-gallery-images  .image-gallery-image{
      height: 272px;
    }
  }

}

@media(max-width: 400px){
  .image-gallery.six-images{
    .tape-first{
      top: 300px;
    }
    .image-gallery-images{
      .image-gallery-image{
        height: 85px;
      }

      &.image-gallery-first{
        .image-gallery-image:last-child{
          height: 240px;
        }
      }

      &.image-gallery-second{
        .image-gallery-image:first-child{
          height: 240px;
        }
      }
    }
  }
  .image-gallery.three-images{

    .image-gallery-images{
      .image-gallery-image{
        height: 85px;
      }
      .image-gallery-image:first-child{
        height: 240px;
      }
    }
  }
  .image-gallery.one-image{
    .image-gallery-images .image-gallery-image{
      height: 240px;
    }
  }
  .image-gallery.two-images{
    .image-gallery-images  .image-gallery-image{
      height: 240px;
    }
  }

}
