.example-styles .content-wrapper{
    padding-top: 105px;
}

body{
  overflow-x: hidden;
  width: 100%;
}
.btn{
  padding: 10px 18px;
  min-width: 152px;
  color: $colour-body;
  max-width: 300px;
  text-decoration: none;
  transition: 0.25s ease;
  display: inline-block;
  text-align: center;
  border: none;
  box-sizing: content-box;
  box-shadow: 0px 1px 2px 0px rgba($colour-black, 0.5);
  &:hover{
    opacity: 1;
  }

  &:active{
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px rgba($colour-black, 0);
  }
}

.btn-block {
  margin-left: -3px;
  margin-right: -3px;
  .btn{
    margin: 8px 3px;
  }
}

.btn-primary{
  background-image: url('/assets/images/button-texture.jpg');
  background-position: top right;
  background-size: 350px 175px;
}
.btn-secondary{
  background-color: $colour-black-btn;
  color: $colour-white;
  &:hover{
    color: $colour-white;
  }
}

.text-centre{
  text-align: center;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-justify{
  text-align: justify;
}

.basic-image{
  max-width: 100%;
  height: auto;
  display: block;
  margin: 15px 0px;
}

.base-background{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $colour-placeholder;
}
.base-texture{
  background-color: $colour-brown;
  background-image: url('/assets/images/texture.jpg');
  background-size: auto;
  background-repeat: repeat;
  background-position: center;
}

.box-shadow{
  box-shadow: 0 8px 26px 0 rgba(0,0,0,0.5);
}
a{
  text-decoration: none;
  transition: 0.25s ease;
  color: $colour-body;
  &:hover{
    color: $colour-black;
    opacity: 0.7;
  }
}

.base-nav{
  ul{
    list-style: none;
    padding-left: 0px;
  }
  li{
    line-height: 32px;
  }
}

.tape{
  position: absolute;
  z-index: 20;
}
.tape-short{
  &.tape-top-centre{
    top: -35px;
    left: 50%;
    transform: translate(-50%) rotate(-10deg);
  }
  &.tape-top-left{
    left: -35px;
    transform: rotate(-22deg);
  }
  &.tape-top-right{
    right: -35px;
    transform: rotate(22deg);
  }
  &.tape-bottom-centre{
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%) rotate(-5deg);
  }
  &.tape-bottom-left{
    bottom: 0px;
    left: -35px;
    transform: rotate(32deg);
  }
  &.tape-bottom-right{
    bottom: 0px;
    right: -35px;
    transform: rotate(-32deg);
  }
  &.tape-centre-left{
    top:  50%;
    left: 0px;
    transform: translate(-50%, -50%) rotate(-95deg);
  }
  &.tape-centre-right{
    top:  50%;
    right: 0px;
    transform: translate(50%,-50%) rotate(-90deg);
  }
}

.tape-long{
  &.tape-top-centre{
    top: -35px;
    left: 50%;
    transform: translate(-50%) rotate(-3deg);
  }
  &.tape-top-left{
    left: -45px;
    transform: rotate(-22deg);
  }
  &.tape-top-right{
    right: -45px;
    top: -10px;
    transform: rotate(20deg);
  }
  &.tape-bottom-centre{
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%) rotate(-175deg);
  }
  &.tape-bottom-left{
    bottom: -25px;
    left: -75px;
    transform: rotate(-150deg);
  }
  &.tape-bottom-right{
    bottom: -12px;
    right: -35px;
    transform: rotate(-12deg);
  }
  &.tape-centre-left{
    top:  50%;
    left: 0px;
    transform: translate(-50%, -50%) rotate(-85deg);
  }
  &.tape-centre-right{
    top:  50%;
    right: 0px;
    transform: translate(50%,-50%) rotate(90deg);
  }
}




@media (max-width: 1375px){
  .tape-short {
      width: 150px;
  }
  .tape-long{
    width: 200px;
  }
}

@media (max-width: 992px){

  .tape-short{
    &.tape-top-centre{
      top: -18px;
    }
    &.tape-bottom-centre{
      bottom: -18px;
    }
  }
  .tape-long{
    &.tape-top-centre{
      top: -18px;
    }
    &.tape-top-left{
      top: 8px;
    }
    &.tape-bottom-centre{
      bottom: -25px;
    }
    &.tape-bottom-left{
      bottom: -16px;
      transform: rotate(-162deg);
    }
    &.tape-bottom-right{
      transform: rotate(-5deg);
    }
  }
}

@media (max-width: 767px){
  .tape-short {
      width: 125px;
  }
  .tape-long{
    width: 175px;
  }
}

@media (max-width: 600px){
  .tape-short {
      width: 100px;
  }
  .tape-long{
    width: 150px;
  }
}

@media (max-width: 550px){
  .tape-long.tape-centre-left, .tape-short.tape-centre-left {
    left: 28px;
  }
  .tape-long.tape-centre-right, .tape-short.tape-centre-right{
    right: 28px;
  }
}


@media (hover: hover){
  .btn:hover{
    box-shadow: 4px 11px 16px -2px rgba($colour-black, 0.5);
    opacity: 1;
  }
  .btn:active{
    box-shadow: 0px 0px 0px 0px rgba($colour-black, 0);
  }

  .btn-primary:hover{
    background-image: url('/assets/images/button-texture-hover.png');
  }
}
