.content-wrapper{
  min-height: calc(100vh - 896px);
  padding-top: 200px;
  margin-bottom: 105px;
  max-width: 100%;
  position: relative;
}

.limited-width{
  max-width: 1500px;
  width: 95%;
  padding: 0px 35px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.limited-width .limited-width{
  max-width: unset;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.container-max{
  position: relative;
  max-width: 2850px;
  width: 100%;
  margin: auto;
}

.half-block{
  display: flex;
  flex-wrap: wrap;
  .half-col{
    flex: 50%;
    max-width: 50%;
    box-sizing: border-box;
  }
  .half-first{
    padding-right: 15px;
  }
  .half-second{
    padding-left: 15px;
  }
}

@media (max-width: 1375px){
  .limited-width{
    max-width: 1190px;
  }
}
@media(max-width: 1299px){
  .content-wrapper{
    padding-top: 194px;
  }
}

@media (max-width: 992px){
  .half-block{
    .half-col{
      max-width: 100%;
      flex: 100%;
    }
    .half-first{
        padding-right: 0px;
    }
    .half-second{
        padding-left: 0px;
    }
    .half-second-m{
      order: 2;
    }
  }
  .limited-width{
    width: 100%;
    padding: 0px 55px;
  }
}

@media(max-width: 675px){
  .content-wrapper {
    padding-top: 242px;
    overflow-x: hidden; 
  }
}

@media (max-width: 550px){
  .limited-width{
    padding: 0px 25px;
  }
}
