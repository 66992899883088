.header-carousel{
  .slick-arrow {
    top: 50%;
  }
  .slick-prev {
    left: 35px;
  }
  .slick-next {
    right: 35px;
  }
  .header-image{
    width: 100%;
    height: 100vh;
    min-height: 750px;
  }
}

.header-carousel-container{
  position: relative;
  margin-bottom: -7px;
  margin-top: -113px;
  .booking-box{
    background-color: $colour-white;
    padding: 60px 35px 40px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;

    .booking-box-tape{
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
    }
    .booking-box-inputs{
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      justify-content: center;
    }
    .booking-box-date{
      flex: 1;
      padding-right: 21px;
      max-width: 385px;
      margin-bottom: 21px;
    }
    .booking-box-guests{
      width: 75px;
    }
    .booking-box-errors{
      flex: 100%;
      color: $colour-error;
      text-align: left;
      transition: padding 0.45s ease;
      margin-bottom: 21px;
      .error{
        margin-right: 12px;
      }
    }
    &.has-errors .booking-box-errors{
      padding: 12px 0px 21px;
    }
    .hosanna-date-picker .datepicker__dummy-wrapper, select {
      border: 1px solid $colour-body;
    }
    .hosanna-date-picker .datepicker {
      top: 48px;
      bottom: unset;
    }
    .ripped-paper{
      background-image: url('/assets/images/ripped paper.png');
      background-size: auto;
      background-repeat: repeat-x;
      background-position: top center;
      height: 62px;
      width: 100%;
      position: absolute;
      top: 99%;
      left: 0px;
    }
  }
}

@media(max-width: 1199px){
  .header-carousel .header-image{
    height: 75vh;
  }
  .header-carousel-container .booking-box .hosanna-date-picker .datepicker {
    left: -30%;
  }
}

@media(max-width: 992px){
  .header-carousel-container{
    .booking-box{
      .booking-box-inputs {
        justify-content: space-between;
      }
      .booking-box-date{
        max-width: unset;
        padding-right: 0px;
        order: 1;
        flex: 100%;
      }
      .booking-box-guests{
        order: 2;
      }
      .btn{
        margin-top: 15px;
        order: 3;
        align-self: flex-end;
        height: 32px;
      }
      .booking-box-errors{
        order: 4;
        margin-bottom: 0px;
        margin-top: 21px;
      }
      .hosanna-date-picker .datepicker {
        left: 50%;
        transform: translateX(-50%);
      }
      .booking-box-tape {
        top: -24px;
        width: 145px;
      }
    }
  }

}
@media(max-width: 767px){
  .header-carousel-container .booking-box {
    width: 90%;
    padding: 60px 25px 40px;
    .booking-box-date{
      padding-right: 0px;
    }
    .hosanna-date-picker{
      .datepicker {
        left: 0;
        height: 600px;
        transform: unset;
      }
      .datepicker.datepicker--open .datepicker__dummy-wrapper{
        border: 0;
      }
    }

  }
  .header-carousel .slick-arrow {
    top: 95%;
  }

}

@media(max-width: 400px){
  .header-carousel-container .booking-box {
    .hosanna-date-picker{
      .datepicker__input {
        padding: 4px 10px 2px;
        width: -webkit-calc(46% + 4px);
        width: calc(46% + 4px);
      }
      .datepicker__input:first-child {
        width: -webkit-calc(54% - 4px);
        width: calc(54% - 4px);
      }
    }
  }
  .header-carousel-container{
    .booking-box{
      .booking-box-guests{
        flex: 100%;
      }
      .btn{
        order: 4;
        height: auto;
      }
      .booking-box-errors{
        order: 3;
      }
    }
  }
}
