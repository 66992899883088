.text-banner{
  background-color: $colour-brown;
  padding: 75px;
  background-image: url('/assets/images/texture.jpg');
  background-size: auto;
  background-repeat: repeat;
  background-position: center;
  text-align: center;
  .text-banner-title{
    margin-bottom: 15px;
  }
  .text-banner-description{
    max-width: 1199px;
    width: 75%;
    margin: auto;
  }
  a:not(.btn){
    color: $colour-body;
    text-decoration: underline;
  }
}

@media(max-width: 992px){
  .text-banner{
    padding: 55px;
    .text-banner-title{
      font-size: 28px;
      line-height: 34px;
    }
    .text-banner-description{
      width: 100%;
    }
  }
}

@media(max-width: 767px){
  .text-banner{
    padding: 45px 35px;
    text-align: left;
  }
}
