$colour-white: #fff;
$colour-black: #000;


$colour-placeholder: #e0e0e0;
$colour-black-btn: #242424;
$colour-body: #1E1E1E;
$colour-brown: #D1B19B;

$colour-error: #b00606;
