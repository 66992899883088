.instagram-block{
  text-align: center;
  .instagram-image{
    width: 100%;
    height: 350px;
  }
  .instagram-handle{
    color: $colour-body;
    font-size: 18px;
    img{
      vertical-align: middle;
    }
  }

  .instagram-slide{
    display: block;
    padding: 35px 15px;
    box-sizing: border-box;
  }
  .instagram-feed-container{
    overflow-x: hidden;
    padding: 15px 0px;
  }

  [id="instagram-feed"]{
    opacity: 0;
    transition: opacity 0.5s ease;
    margin-right: -15px;
    margin-left: -15px;
  }

  [id="instagram-feed"].slick-initialized{
    opacity: 1;
  }

  .slick-slider{
    .slick-arrow{
      top: 50%;
      height: 105px;
      width: 105px;
    }
    .slick-prev{
      left: 25px;
    }
    .slick-next{
      right: 25px;
    }
  }
}

@media(max-width: 2000px){
  .instagram-block .instagram-image{
    height: 290px;
  }
}

@media(max-width: 992px){
  .instagram-block .slick-slider{
    .slick-arrow{
      height: 95px;
      width: 95px;
    }
    .slick-prev{
      left: 15px;
    }
    .slick-next{
      right: 15px;
    }
  }

}

@media(max-width: 400px){
  .instagram-block .instagram-image{
    height: 190px;
  }
}
