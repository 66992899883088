.slick-slider{
  .slick-arrow{
    z-index: 20;
    top: 21%;
  }
  .slick-prev, .slick-next{
    width: 21px;
    height: 34px;
  }
}
