.featured-block{
  display: flex;
  flex-wrap: wrap;
  min-height: 500px;
  padding: 35px 0px;
  margin-top: 75px;
  margin-bottom: 75px;

  &.no-tape{
    padding: 0px;
  }

  .featured-block-media{
    position: absolute;
    width: 50%;
    box-sizing: border-box;
    box-shadow: 0px 6px 24px rgba(0,0,0, 0.5);
    .base-background{
      height: 100%;
      width: 100%;
      transition: transform 10s linear;
      &:hover{
          transform: scale(1.15);
      }
    }
  }

  .featured-block-media-items{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    .feature-block-item{
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    &.items-2 .feature-block-item{
      max-width: 50%;
    }
    &.items-3{
      .feature-block-item{
        max-width: 50%;
        max-height: 40%;
      }
      .feature-block-item:first-child{
        max-width: 100%;
        max-height: 60%;
      }
    }
    &.items-4 .feature-block-item{
      max-width: 50%;
      max-height: 50%;
    }
  }

  .featured-block-content{
    max-width: 50%;
    align-self: center;
    box-sizing: border-box;
  }


}

.featured-block.fb-right{
  .featured-block-media{
    order: 2;
    right: 0px;
  }
  .featured-block-content{
    order: 1;
    padding-right: 60px;
  }
}

.featured-block.fb-left{
  .featured-block-media{
    left: 0px;
  }
  .featured-block-content{
    padding-left: 60px;
    margin-left: auto;
  }
}

.feature-block-item-video{
  position: relative;
  &:hover{
    cursor: pointer;
  }
  &::after{
    background-image: url('/assets/images/play-button.png');
    background-size: contain;
    width: 55px;
    height: 55px;
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.featured-block-tingle{
  .tingle-modal-box__content, .tingle-modal-box__footer{
    padding: 0px;
  }
  .tingle-modal-box{
    background-color: transparent;
  }
  .tingle-video{
    height: 500px;
    width: 100%;
    max-width: 891px;
    margin: auto;
    display: block;
  }
}

.featured-block-media{
  width: 100%;
  height: 500px;
}


.featured-block-media-items.slick-initialized{
  display: block;
  .feature-block-item{
    min-height: 350px;
    width: 100%;
    max-width: unset !important;
    max-height: unset !important;
  }
  .slick-list, .slick-track, .slick-slide{
    height: 100%;
  }
  .slick-slide > div{
    height: 100%;
    position: relative;
  }
  .slick-arrow{
    bottom: 55px;
    top: unset;
  }
  .slick-prev{
    left: 55px;
  }
  .slick-next{
    right: 55px;
  }
}


.cell {
  display: flex;
  .featured-block {
    min-height: unset;
    max-width: 50%;
    flex: 50%;
    .featured-block-media {
      height: 450px;
      position: relative;
      width: 100%;
    }
    .featured-block-content {
      max-width: unset;
      padding: 30px 35px 30px 0px;
      width: 100%;
      height: 100%;
    }
    &.fb-right {
      .featured-block-media {
        order: 1;
      }
      .featured-block-content {
        order: 2;
      }
    }
  }
  .featured-block:first-child {
    padding-right: 15px;
  }
  .featured-block:last-child {
    padding-left: 15px;
  }
}


@media(min-width: 1920px){
  .featured-block{
    min-height: 650px;
    .featured-block-media{
      height: 650px;
    }
  }
  .featured-block-media-items.slick-initialized .feature-block-item{
    height: 650px;
  }
  .cell .featured-block .featured-block-media{
    height: 450px;
  }
}

@media(max-width: 1199px){
  .cell .featured-block .featured-block-media{
    height: 300px;
  }
}

@media (max-width: 992px){
  .featured-block{
    margin-right: -55px;
    margin-left: -55px;
    &.no-tape{
      margin-top: 0px;
      margin-bottom: 60px; 
    }
    .featured-block-media{
      width: 100%;
      position: relative;
    }
    .featured-block-content{
      max-width: unset;
    }
  }

  .featured-block.fb-right{
    .featured-block-media{
      order:1;
    }
    .featured-block-content{
      order: 2;
      padding-right: 55px;
      padding-left: 55px;
    }
  }
  .featured-block.fb-left{
    .featured-block-content{
      padding-right: 55px;
      padding-left: 55px;
    }
  }
  .featured-block-content{
    padding-top:  42px;
    padding-bottom:28px;
  }
  .featured-block{
    display: block;
  }


  .cell{
    display: block;
    .featured-block{
      max-width: unset;
      .featured-block-content {
        padding: 42px 55px 28px;
      }
      .featured-block-media{
        height: 500px;
      }
    }
    .featured-block:first-child{
      padding-right: 0px;
    }
    .featured-block:last-child{
      padding-left: 0px;
    }
  }

}


@media (max-width: 768px){
  .featured-block-media, .cell .featured-block .featured-block-media {
    height: 400px;
  }
  .featured-block-media-items.slick-initialized .feature-block-item{
    height: 400px;
  }
}

@media (max-width: 540px){
  .featured-block-media, .cell .featured-block .featured-block-media {
    height: 275px;
  }
  .featured-block-media.no-tape{
    margin-bottom: 40px;
  }
  .featured-block-media-items.slick-initialized .feature-block-item{
    height: 275px;
    min-height: unset;
  }

  .featured-block-tingle .tingle-modal__close {
    background-color: $colour-brown;
    background-image: url('/assets/images/button-texture.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .featured-block-media-items.slick-initialized{
    .slick-arrow{
      bottom: unset;
      top: 50%;
    }
  }
}
