@import 'base/variables';
@import 'base/base';

@import 'base/typography';
@import 'base/forms';
@import 'base/slider-base';
@import 'base/instafeeds';

@import 'layouts/header';
@import 'layouts/footer';
@import 'layouts/layouts';

@import 'plugins/slick';
@import 'plugins/slick-theme';
@import 'plugins/tingle';

//blocks
@import 'blocks/featured-block.scss';
@import 'blocks/text-banner.scss';
@import 'blocks/hero-image-block.scss';
@import 'blocks/booking-bar.scss';
@import 'blocks/testimonials-carousel.scss';
@import 'blocks/header-carousel.scss';
@import 'blocks/video-header.scss';
@import 'blocks/image-gallery.scss';
@import 'blocks/accordion-element.scss';
@import 'blocks/instagram-block.scss';
